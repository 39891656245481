import React from "react"
import { FC } from "react"
import AdminModule from "../../admin-module";
import StyledButton from "../../../../components/styled-button/styled-button";

const V2Link: FC = (props) => (
    <AdminModule header="V2 preview">
        <p>Preview the redesigned UI and new features</p>
        <a href="/v2/">
            <StyledButton>
                Go
            </StyledButton>
        </a>
    </AdminModule>
)

export default V2Link;