
export interface Environments {
    localhost: boolean,
    dev: boolean,
    uat: boolean,
    preprod: boolean,
    prod: boolean
}

export const isDesiredEnvironment = (environments:Environments):boolean => {
    const hostName: string = window.location.hostname;
    
        if(environments.localhost && hostName.indexOf("localhost") > -1) {
            return true;
        }else if(environments.dev && hostName.indexOf("dev") > -1) {
            return true;
        }else if(environments.uat && hostName.indexOf("uat") > -1) {
            return true;
        }else if(environments.preprod && hostName.indexOf("preprod") > -1) {
            return true;
        }else if(environments.prod && hostName.indexOf("listings") > -1 
                && hostName.indexOf("dev") === -1 
                && hostName.indexOf("uat") === -1 
                && hostName.indexOf("preprod") === -1) {   
            return true;
        }
    
    return false;
}