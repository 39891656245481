import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { InsightsRecord } from '../../../types/insights/insights-record';
import InsightsPropertyOverview from './insights-property-overview';
import InsightsNavBar from './insights-navbar';
import { Grid, Row, Col } from 'react-styled-flexboxgrid';
import InsightsShareDialog from './sharing/insights-share-dialog';
import DiagnolArrowIcon from '../../../assets/images/png/diagnol-arrow-icon.png';
import { getLeadsReport } from '../../../api/glQueries';
import { postData } from '../../../api/glAxios';

export interface Props {
    navTitle: string,
    record: InsightsRecord
};

const InsightsRecordPage: FC<Props> = (props) => {

    const { navTitle, record } = props;
    const listing = record.listing;

    const [showInsightsShareDialog, setShowInsightsShareDialog] = useState<boolean>(false);
    const [leadsReport, setLeadsReport] = useState<any[]>([]);

    const getLeadsData = () => {
        if (listing && listing.externalId) {
            postData(getLeadsReport(parseInt(listing.id,10)))
                .then((response: any) => {
                    if (response.data && response.data.getLeadsReport) {
                        setLeadsReport(response.data.getLeadsReport);
                    }
                    else {
                        setLeadsReport([]);
                    }
                });
        }

    }

    // note: this page is meant to be a layout page. as we build out reporting sections, we will add them here
    return (
        <Container>
            <InsightsNavBar navTitle={navTitle} showShareButton={true} showPrintButton={true} clickShareHandler={() => setShowInsightsShareDialog(true)} />
            <Grid>
                <Row>
                    <Col sm={1} style={{ maxWidth: '150px', borderRight: '1px solid #eee' }}>
                        {listing && listing.state && listing.state.toLowerCase() === "published" && listing.externalPublishUrl && listing.externalPublishUrl.length > 0 &&
                            <ViewPropertyLink><a target="_blank" href={listing.externalPublishUrl}>
                                View Listing <img src={DiagnolArrowIcon} alt="View Property" /></a>
                            </ViewPropertyLink>
                        }
                    </Col>
                    <Col sm={10}>
                        <InsightsPropertyOverview record={record} leadsClick={getLeadsData} />
                        <Spacer />
                    </Col>
                </Row>
            </Grid>
            <Grid style={{ width: "75%", marginLeft: "16%" }}>
                <Row>
                    {leadsReport && leadsReport.length > 0 &&
                        <AlertMessage>
                            <RowHeading>
                                <Row style={{ justifyContent: 'space-around' }}>
                                    <Col xs={3} style={{ paddingLeft: "1.5rem" }}>
                                        <h4>Email Address</h4>
                                    </Col>
                                    <Col xs={2}>
                                        <h4>Company Name</h4>
                                    </Col>
                                    <Col xs={2}>
                                        <h4>Name</h4>
                                    </Col>
                                    <Col xs={2}>
                                        <h4>Phone</h4>
                                    </Col>
                                    <Col xs={2}>
                                        <h4>Role</h4>
                                    </Col>
                                    <Col xs={1}>
                                        <h4>Contact Date</h4>
                                    </Col>
                                </Row>
                            </RowHeading>
                            {leadsReport.map((option: any, key: number) => {
                                return <RowContent key={key}>
                                    <Row style={{ justifyContent: 'space-around' }}>
                                        <Col xs={3} style={{ paddingLeft: "1.5rem" }}>
                                            <p>{option.emailAddress} </p>
                                        </Col>
                                        <Col xs={2}>
                                            <p>{option.companyName} </p>
                                        </Col>
                                        <Col xs={2}>
                                            <p>{option.contactName}</p>
                                        </Col>
                                        <Col xs={2}>
                                            <p>{option.phoneNumber}</p>
                                        </Col>
                                        <Col xs={2}>
                                            <p>{option.roleName}</p>
                                        </Col>
                                        <Col xs={1}>
                                            <p>{new Date(option.contactDate).toLocaleDateString()}</p>
                                        </Col>
                                    </Row>
                                </RowContent>
                            })}
                        </AlertMessage>
                    }
                </Row>
            </Grid>
            {showInsightsShareDialog && <InsightsShareDialog shareLink={window.location.href} closeHandler={() => setShowInsightsShareDialog(false)} />}
        </Container>
    )
};

const Container = styled.div`
    width: 100%;
`;

const Spacer = styled.div`
    width: 100%;
`;

const ViewPropertyLink = styled.div`
    margin-top: 20px;
    width: 120px;
    height: 30px;
    line-height: 30px;
    background: #006A4D;
    color: #FFFFFF;
    border-radius: 2px;
    text-align: center;
    cursor: pointer;
    a, a:visited {
        color: #FFFFFF;
        text-decoration: none;
    }
`;

const AlertMessage = styled.div`
    margin-top: 20px;
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    word-wrap: break-word;
`;
const RowHeading = styled.div`
    background: rgba(26, 26, 26, 0.04); 
    border-width: 1px 0px;
    border-style: solid;
    border-color: rgba(0, 63, 45, 0.15);
    `;

const RowContent = styled.div`
    border-width: 1px 0px;
    border-style: solid;
    border-color: rgba(0, 63, 45, 0.15);
    display: grid;
    `;

export default InsightsRecordPage;