import { postData } from '../../../api/glAxios'
import { removeUserFromRegion } from '../../../api/glQueries'


export const revokeAccessToRegion = (user:string,regionId:string|undefined) => {

    return new Promise((resolve, reject) => {
        postData(removeUserFromRegion(user, regionId)).then((result: any) => {
            if(!result.data){
                reject("There was an error saving this contact.");
            }else{
                resolve(result);
            }
        }).catch((error:any) => {
            reject("There was an error saving this contact.");
        });
    });    
}