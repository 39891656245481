
import React, { FC } from "react";
import AE from '../../../assets/images/png/flags/AE.png';
import DK from '../../../assets/images/png/flags/DK.png';
import FI from '../../../assets/images/png/flags/FI.png';
import IN from '../../../assets/images/png/flags/IN.png';
import IT from '../../../assets/images/png/flags/IT.png';
import MX from '../../../assets/images/png/flags/MX.png';
import NL from '../../../assets/images/png/flags/NL.png';
import NO from '../../../assets/images/png/flags/NO.png';
import PL from '../../../assets/images/png/flags/PL.png';
import SG from '../../../assets/images/png/flags/SG.png';
import UK from '../../../assets/images/png/flags/UK.png';
import US from '../../../assets/images/png/flags/US.png';
import PT from '../../../assets/images/png/flags/PT.png';
import IM from '../../../assets/images/png/flags/IM.png';
import AU from '../../../assets/images/png/flags/AU.png';
import REAL from '../../../assets/images/png/flags/REAL.png';


const siteIcons = {
    'ae-comm': AE,
    'dk-comm': DK,
    'pt-comm': PT,
    'fi-comm': FI,
    'in-comm': IN,
    'it-comm': IT,
    'mx-comm': MX,
    'nl-comm': NL,
    'no-comm': NO,
    'pl-comm': PL,
    'sg-comm': SG,
    'uk-comm': UK,
    'us-comm': US,
    'im-comm': IM,
    'us-im': IM,
    'au-comm': AU,
    'real-comm': REAL,
}

interface Props {
    className?: string,
    siteId: string,
    width?: string,
    height?: string,
}

const SiteIcon: FC<Props> = (props) => {
    const src = siteIcons[props.siteId];
    const height = props.height || "20px";
    const width = props.width || "28px";
    return !src ? null :
        <img
            className={props.className}
            src={src}
            height={height}
            width={width} />;
}

export default SiteIcon;