import React, { ChangeEvent, FC, useRef, useState } from "react";
import { uploadFile } from "../../api/files/file-upload";
import styled from "styled-components";
import StyledButton from "../../components/styled-button/styled-button";
import { clearMessage, setMainMessage } from '../../redux/actions/system/set-main-message';
import { useDispatch, useSelector } from "react-redux";
import { MainMessageType } from "../../types/state";
import { mainMessageSelector } from "../../redux/selectors/system/main-message-selector";
import { MainMessaging } from "../../types/state";

const BrokerImageUpload: FC = (props) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File | null>(null);
  const [fileUploadText, setFileUploadText] = useState<string>("");

  const dispatch: any = useDispatch();

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if(!event.target.files || (event.target.files && event.target.files.length < 1)){
      setFileUploadText("select file.");
      return;
    }
    setFileUploadText("");
    const selectedFile = event.target.files && event.target.files[0];
    setFile(selectedFile);
  };

  const handleFileUpload = () => {
    if(file === null){
      setFileUploadText("select file.");
      return;
    }
    dispatch(setMainMessage({ show: true, type: MainMessageType.LOADING, message:"Uploading Image..." }));
    uploadFile(file as File, "/api/FileUpload/ExternalSystemContactAvatar").then((respone: any) => {
        clearMessage(dispatch);
        if (null !== fileInputRef.current) {
          fileInputRef.current.value = "";
        }
        setFileUploadText("Image upload successfully.");
        setFile(null);
      }).catch((err) => {
        clearMessage(dispatch);
        if(typeof err === "string"){
          setFileUploadText(err.toString());
        }
        else
        {
          setFileUploadText("Error while uploading.");
          console.log(err);
        }

      });
  };

  return (
    <>
      <SyledDropContainer>
        <StyledFileInput type="file" id="images" accept="image/*" onChange={handleFileChange} ref={fileInputRef}/>
        <StyledButton type="button" name={"uploadImage"} onClick={handleFileUpload}style={{marginTop: "38px", textAlign: "center", backgroundColor: "#006a4d"}}>
          Upload
        </StyledButton>
        <label>{fileUploadText}</label>
      </SyledDropContainer>
      
    </>
  );
};

const StyledFileInput = styled.input`
  width: 350px;
  max-width: 100%;
  color: #444;
  padding: 5px;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #555;
  &::file-selector-button {
    margin-right: 20px;
    border: none;
    background: #006a4d;
    padding: 10px 20px;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    transition: background 0.2s ease-in-out;
    &:hover {
      background: #006a4d;
    }
  }
`;

const SyledDropContainer = styled.div`
  position: relative;
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* height: 200px; */
  padding: 20px;
  border-radius: 10px;
  border: 2px dashed #555;
  color: #444;
  cursor: pointer;
  margin-top: 50px;
  transition: background 0.2s ease-in-out, border 0.2s ease-in-out;
  &:hover {
    background: #eee;
    border-color: #111;
  }
  .drop-title {
    color: #222;
  }
`;

const StyledDropTitle = styled.span`
  color: #444;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  transition: color 0.2s ease-in-out;
`;

export default BrokerImageUpload;
