import { postData } from '../../../api/glAxios'
import { addSelfToRegion, pullAllRegions } from '../../../api/glQueries'


export const getAllRegion = (skipUserAccess:boolean) => {

    return new Promise((resolve, reject) => {
        postData(pullAllRegions(skipUserAccess)).then((result: any) => {
            if(!result.data){
                reject("There was an error saving this contact.");
            }else{
                resolve(result);
            }
        }).catch((error:any) => {
            reject("There was an error saving this contact.");
        });
    });    
}



export const getAccessToRegion = (regionId:string) => {

    return new Promise((resolve, reject) => {
        postData(addSelfToRegion(regionId)).then((result: any) => {
            if(!result.data){
                reject("There was an error saving this contact.");
            }else{
                resolve(result);
            }
        }).catch((error:any) => {
            reject("There was an error saving this contact.");
        });
    });    
}