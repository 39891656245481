import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userSelector } from "../../redux/selectors/system/user-selector";
import { MainMessaging, User } from "../../types/state";
import styled from "styled-components";
import BrokerImageUpload from "./brokerimageupload";
import LoadingContainer from "../../components/loader/loading-container";
import MainMessage from "../common/messages/main-message";
import { mainMessageSelector } from "../../redux/selectors/system/main-message-selector";

const BrokerImageUploadContainer: FC = (props) => {
  const user: User = useSelector(userSelector);
  const dispatch: any = useDispatch();
  const mainMessage: MainMessaging = useSelector(mainMessageSelector);

  return (
    <>
      {user && user.isExternalSystemBrokerAvatarUploadRole && (
        <div>
          <StyledListingsHeader>
            <StyledInnerContainer>
              <StyledTitle style={{float: "left", marginRight: "15px", marginTop: "8px", marginBottom: "-8px",}}>
                External System Broker Image Upload
              </StyledTitle>
            </StyledInnerContainer>
          </StyledListingsHeader>
          <Container>
            <BrokerImageUpload />
          </Container>
          <div style={{marginTop : "50px"}}>
          <LoadingContainer isLoading={mainMessage.show ? false : true} psuedoEditNav={true}>
              {mainMessage.show &&
                  <MainMessage mainMessage={mainMessage} />
              }
          </LoadingContainer>
        </div>
        </div>
      )}
    </>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* height: 70vh; */
  flex-direction: column;
`;

const StyledListingsHeader = styled.div`
  background: #006a4d;
  overflow: auto;
  padding: 12px 0;
  font-family: ${(props) =>
    props.theme.font ? props.theme.font.primary : "sans-serif"};
  position: sticky;
  top: 0;
  z-index: 100;
  height: 37px;
  box-shadow: 0 4px 6px rgba(204, 204, 204, 0.12),
    0 2px 4px rgba(204, 204, 204, 0.24);
`;

const StyledInnerContainer = styled.div`
  max-width: ${(props) => props.theme.container.maxWidth};
  width: ${(props) => props.theme.container.width};
  margin: 0 auto;
`;

const StyledTitle = styled.h1`
  display: inline-block;
  color: white;
  float: left;
  text-transform: uppercase;
  font-size: 1.3em;
  font-family: ${(props) =>
    props.theme.font ? props.theme.font.bold : "sans-serif"};
  padding: 0px;
`;

export default BrokerImageUploadContainer;
