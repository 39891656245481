
import React, { FC, useContext, useState } from 'react'
import { Col, Row } from 'react-styled-flexboxgrid'
import SectionHeading from "../../../components/section-heading/section-heading";
import styled from 'styled-components';
import MultiDropdown from './multi-dropdown/multi-dropdown';
import { Config, SizeTypeConfig } from '../../../types/config/config';
import StyledButton from '../../../components/styled-button/styled-button';
import { generateKey } from '../../../utils/keys';
import { SizeTypeOption } from '../../../types/common/option';
import { findSizeTypeOptions } from '../../../utils/config/sizeType-options';
import { Listing } from '../../../types/listing/listing';
import { ManualError } from '../validations/published';

export interface SizeTypeProps {
    name: string,
    setup: SizeTypeConfig
}

interface Props {
    config: Config,
    sizeTypeSetup: SizeTypeConfig,
    sizeTypes?: SizeType[],
    changeHandler: (sizeTypes: SizeType[]) => void,
    listing: Listing,
    error: ManualError
}

export interface SizeType {
    sizeKind: string,
    amount: any,
    measureUnit: string
}

const SizesAndMeasurements: FC<Props> = (props) => {

    const { config, sizeTypeSetup, sizeTypes, changeHandler, listing, error } = props;

    const [data, setData] = useState<SizeType[]>(sizeTypes ? sizeTypes : []);

    const sizeTypeConfig: SizeTypeConfig = sizeTypeSetup.use && sizeTypeSetup.use.length > 0 && config[sizeTypeSetup.use] ? config[sizeTypeSetup.use] : sizeTypeSetup;
    const sizeTypeOptions: SizeTypeOption[] = sizeTypeConfig && sizeTypeConfig.sizeType ? findSizeTypeOptions(sizeTypeConfig, listing.propertyType) : [];


    // get the default unit of measurement from the config
    let defaultUnitOfMeasurement: string = "sqm";
    if (config && config.defaultMeasurement && config.defaultMeasurement !== '') {
        defaultUnitOfMeasurement = config.defaultMeasurement
    }

    const clearFocus = () => {
        const el: any = document.querySelector(':focus');
        if (el) {
            el.blur();
        }
    }

    const addRow = () => {
        const temp: SizeType[] = data;
        temp.push({
            sizeKind: '',
            amount: 0,
            measureUnit: ''
        });
        setData([...temp]);
    }

    const deleteRow = (index: number) => {
        const temp: SizeType[] = data;
        temp.splice(index, 1);
        setData([...temp]);
    }

    const sizeChangeHandler = (values: any, index: number) => {
        const temp: SizeType[] = data;
        temp[index] = {
            sizeKind: values.sizeKind,
            measureUnit: values.measureUnit,
            amount: Number(values.amount)
        };
        const newSizeTypes: SizeType[] = [...temp];
        setData(newSizeTypes);
        changeHandler(newSizeTypes);
    }

    const DisplayError = () => {
        return (
            <Row>
                <Col sm={12}><ErrorText>{error.message}</ErrorText></Col>
            </Row>
        )
    }

    return (
        <SizesContainer>
            <Row>
                <Col id="sizes" xs={12}><SectionHeading error={error && error.error}>{sizeTypeConfig.header}</SectionHeading></Col>
                {config && config.sizesandmeasurements && config.sizesandmeasurements.helpText &&
                    <StyledSubtext> ({config.sizesandmeasurements.helpText})</StyledSubtext>
                }
            </Row>
            {error && error.error && <DisplayError />}
            {data.map((iteration: any, index: number) => (
                <MultiDropdown name={'sizeandm' + index} key={generateKey()}
                    index={index} deleteRow={deleteRow} changeHandler={sizeChangeHandler}
                    sizeKind={sizeTypeOptions ? sizeTypeOptions : []}
                    measureUnit={sizeTypeConfig.unitofmeasure ? sizeTypeConfig.unitofmeasure : []}
                    values={data[index]}
                    defaultUnitOfMeasurement={defaultUnitOfMeasurement}
                />
            ))}
            <StyledButton name={'add size'} onMouseOver={clearFocus} onClick={addRow} style={{ marginTop: '25px' }} styledSpan={true} buttonStyle="2"><span style={{ fontSize: "18px" }}>+</span>&nbsp;&nbsp; Add Size</StyledButton>
        </SizesContainer>
    )
}

const SizesContainer = styled.div`
    #Sizes{
        h2{
            margin-top:45px;
            margin-bottom:0;
            color: #8E9A9D !important;
            font-size:18px;
        }
    }
`

const ErrorText = styled.div`
    color: ${props => (props.theme.colors ? props.theme.colors.error : 'red')}; 
`;

const StyledSubtext = styled.div`
  color: #9EA8AB;
  margin-block-start: -1em;
  font-family: "futura PT Book italic";
  font-size: 14px;
  margin-left: 0.5em;
`;

export default SizesAndMeasurements;