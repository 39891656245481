import React, { FC, useEffect, useContext } from 'react';
import Select from 'react-select';
import SiteIcon from './site-icon';
import styled from 'styled-components';
import { configDetailsSelector } from '../../../redux/selectors/system/config-details-selector';
import { GLAnalyticsContext } from '../../../components/analytics/gl-analytics-context';
import { loadConfig } from '../../../redux/actions/system/load-config-details';
import { matchPath } from 'react-router';
import { RoutePaths } from '../../../app/routePaths';
import { routeSelector } from '../../../redux/selectors/router/route-selector';
import { setCountry } from '../../../redux/actions/mapping/set-country';
import { setCountryCode } from '../../../redux/actions/mapping/set-country-code';
import { useSelector, useDispatch } from 'react-redux';
import { loadTeams } from '../../../redux/actions/teams/load-teams';
import { setMainMessage } from '../../../redux/actions/system/set-main-message';
import { MainMessageType } from '../../../types/state';

interface Props {
    config: any
}

const RegionSelect: FC<Props> = (props) => {

    const dispatch = useDispatch();
    const { config } = props;
    const configDetails: any = useSelector(configDetailsSelector);
    const route: string = useSelector(routeSelector);
    const analytics = useContext(GLAnalyticsContext);

    const options = configDetails.regions.map((region: any, index: number) => {
        return {
            value: region.homeSiteID,
            order: index,
            label: 
                <StyledOption>
                    <StyledIcon siteId={region.homeSiteID} />
                    {region.name}
                </StyledOption>
        };
    })

    const selected = (value: any) => {
        const siteId = value.value;
        analytics.fireEvent('home', 'click', 'Country selected: ' + siteId)
        
        // Set the siteId selected in local storage
        // So the user's lastest country choice stays selected
        if (typeof(Storage) !== "undefined") {
            localStorage.setItem("siteId", siteId);
        }

        refreshPLP(siteId);
    }

    const initialValue = options.filter((option: any) => {
        return option.value === config.siteId
    })[0] || options[0];

    const refreshPLP = (siteId: any) => {
        let regionsFiltered = configDetails.regions.filter((r:any) => r.homeSiteID === siteId);
        if(!(regionsFiltered && regionsFiltered.length > 0)) {
            regionsFiltered = configDetails.regions;
        }
        const regionId = regionsFiltered[0].iD;
        const newSiteId = regionsFiltered[0].homeSiteID;
        dispatch(setCountry(regionId));
        dispatch(loadConfig(newSiteId, true));
        dispatch(setCountryCode(newSiteId.substring(0, 2)));
        dispatch(loadTeams());
        dispatch(setMainMessage({ show: true, type: MainMessageType.LOADING, message: "Loading..." }));
    };


    const noEntryRoutes = ['admin','brokerImageUpload'];
    let noEntry = false;
    const readOnlyRoutes = ['listingEntry'];
    let readOnly = false;
    for (const [key, value] of Object.entries(RoutePaths)) {
        const match = matchPath(route, value);
        if (match && match.isExact) { // if matchPath confirms same route
            if (noEntryRoutes.includes(key)) {
                noEntry = true;
            }
            if (readOnlyRoutes.includes(key)) {
                readOnly = true;
            }
        }
    }

    useEffect(() => {
        refreshPLP(config.siteId);
    }, [config])


    if (noEntry || !configDetails.regions || configDetails.regions.length < 1) {
        return null;
    } else if (readOnly || configDetails.regions.length < 2) {
        return (
            <StyledContainer>
                <StyledReadOnlyContainer>
                    {initialValue.label}
                </StyledReadOnlyContainer>
            </StyledContainer>
        );
    } else {
        return (
            <StyledContainer>
                <StyledSelect 
                    id="regionSelect" // used by test automation suite
                    onChange={selected} 
                    options={options} 
                    value={initialValue} />
            </StyledContainer>
        );
    }

};

export default RegionSelect;


const StyledContainer = styled.div`
    width: 600px;
    z-index: 5;
    position: relative;
`;

const StyledReadOnlyContainer = styled.div`
    width: 200px;
    margin-top: 5px;
    padding-top: 9px;
    padding-left: 10px;
    color: #666;
`

const StyledSelect = styled(Select)`
    > div {
        border: none;
    }
    width: 200px;
    margin-top: 5px;
    color: #666;

`;

const StyledOption = styled.div`
    display: flex;
    align-items: center;
    font-family: "Futura Md BT";
    white-space: normal;
`;

const StyledIcon = styled(SiteIcon)`
    margin-right: 15px;
`