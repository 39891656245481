import React, { useEffect, useState } from "react"
import { FC } from "react"
import { Col, Row } from 'react-styled-flexboxgrid';
import Select from 'react-select';
import SiteIcon from "../../../common/header/site-icon";
import styled from 'styled-components';
import AdminModule from "../../admin-module";
import StyledButton from "../../../../components/styled-button/styled-button";
import SearchableInput, { SearchableInputNoDataProps } from "../../../../components/searchable-input/searchable-input";
import { AutoCompleteRequest, AutoCompleteResult } from "../../../../types/common/auto-complete";
import { getUsersForSearch } from "../../../../api/users/users";
import { TeamMember } from "../../../../types/team/teamMember";
import { configDetailsSelector } from "../../../../redux/selectors/system/config-details-selector";
import { useDispatch, useSelector } from "react-redux";
import { grantAccessToRegion } from "../../../../redux/actions/admin/grant-access-region";
import { revokeAccessToRegion } from "../../../../redux/actions/admin/revoke-access-region";
import { regionIdSelector } from "../../../../redux/selectors/system/config-region-id-selector";

interface Region {
    value: string;
    order: number;
    regionId: string;
    label: any;
}

interface StyleComponentProps {
    isError: boolean;
}

const AdminAccessRegion: FC = (props) => {

    const configDetails: any = useSelector(configDetailsSelector);
    const dispatch = useDispatch();


    const options = configDetails.regions.map((region: any, index: number) => {
        return {
            value: region.homeSiteID,
            order: index,
            regionId: region.iD,
            label:
                <StyledOption>
                    <StyledIcon siteId={region.homeSiteID} />
                    {region.name}
                </StyledOption>
        };
    })

    const [tempuser, setTempUser] = useState({} as TeamMember);
    const [initialValue, setIntialValue] = useState({} as Region);
    const [messageText, setMessageText] = useState<string>("");
    const [isError, setIsError] = useState(false);

    const findRemoteDataProvider = (request: AutoCompleteRequest): Promise<AutoCompleteResult[]> => {
        return new Promise((resolve, reject) => {
            resolve(getUsersForSearch(request));
        });
    }

    const getUser = (suggestion: AutoCompleteResult) => {
        const user = suggestion.value;
        setTempUser(user)
        setMessageText("");
    }
    const noDataProps: SearchableInputNoDataProps = {
        showNoData: true,
        noDataMessage: 'No users found...',
        showNoDataButton: false
    }

    const selected = (value: any) => {
        const siteId = value.value;
        const selectedRegion = options.find((option: any) => {
            return option.value === siteId;
        })
        setIntialValue(selectedRegion);
    }


    const grantAccess = () => {
        if (tempuser.email === "" || tempuser.email === undefined  || initialValue.regionId === "" || initialValue.regionId === undefined) {
            setIsError(true);
            setMessageText("email-id and region  is required");
            return;
        }

        grantAccessToRegion(tempuser.email, initialValue.regionId).then((result: any) => {
            if (result.data.addUserToRegion) {
                setIsError(false);
                setMessageText(`For ${tempuser.email} Access granted to the region - ${initialValue.value}`);
                setIntialValue({} as Region);
                setTempUser({} as TeamMember);
            }
        }).catch((error: string) => {
            setMessageText(error);
        });
    }

    const revokeAccess = () => {
        if (tempuser.email === "" || tempuser.email === undefined || initialValue.regionId === "" || initialValue.regionId === undefined) {
            setIsError(true);
            setMessageText("email-id and region  is required");
            return;
        }

        revokeAccessToRegion(tempuser.email, initialValue.regionId).then((result: any) => {
            if (result.data.removeUserFromRegion) {
                setIsError(false);
                setMessageText(`For ${tempuser.email} access revoked to the region - ${initialValue.value}`);
                setIntialValue({} as Region);
                setTempUser({} as TeamMember);
            }
        }).catch((error: string) => {
            setMessageText(error);
        });
    }


    return (
        <AdminModule header="Grant or Revoke access to region">
            {<>
                {messageText && <ErrorText isError={isError}>{messageText}</ErrorText>}
                <Row>
                    <Col xs={12}>
                        <SearchableInput key={"userSearch" + new Date().getTime()} name="userSearch" placeholder="Lookup a member" remoteDataProvider={findRemoteDataProvider}
                            autoCompleteFinish={getUser} defaultValue={tempuser && tempuser.email} clearAfterSelect={false} error={false} showSearchIcon={true} noDataProps={noDataProps} />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <StyledContainer>
                            <StyledSelect
                                id="regionSelect" // used by test automation suite                            
                                onChange={selected}
                                options={options}
                                value={initialValue}
                            />
                        </StyledContainer>
                    </Col>
                </Row>

                <Row style={{ justifyContent: 'flex-end', marginTop: '4px' }}>
                    <Col >
                        <StyledButton type="button" style={{ marginRight: '10px' }} onClick={() => grantAccess()} >
                            Grant
                        </StyledButton>
                        <StyledButton type="button" disabled={!tempuser.email} onClick={() => revokeAccess()}>
                            Revoke
                        </StyledButton>
                    </Col>
                </Row>
            </>}

        </AdminModule>
    );
}

export default AdminAccessRegion;

const ErrorText = styled.div<StyleComponentProps>`
    color: ${props => !props.isError ? '#00a384' : 'darkred'};
    font-size: 14px;
    margin: 15px 0 0 0;
`;

const StyledContainer = styled.div`
    width: auto;
    z-index: 5;
    position: relative;
`;

const StyledSelect = styled(Select)`
> div {
   border-radius:0px;
   heght:42px;
}
    width: auto;
    margin-top: 5px;
    color: #666;
`;


const StyledOption = styled.div`
    display: flex;
    align-items: center;
    font-family: "Futura Md BT";
    white-space: normal;
`;

const StyledIcon = styled(SiteIcon)`
    margin-right: 15px;
`




