import React from "react"
import { FC } from "react";
import styled from "styled-components";

export interface AdminModuleProps {
    header?: string
}

const AdminModule : FC<AdminModuleProps> = (props) => (
    <Container>
        <InnerContainer>
            <BoundingBox>
                {props.header && <DisplayHeader>{props.header}</DisplayHeader>}
                {props.children}
            </BoundingBox>
        </InnerContainer>
    </Container>
)

const Container = styled.div`
    flex: 0 50%;
`;

const InnerContainer = styled.div`
    font-family: 'Futura Md BT', helvetica, arial, sans-serif;
    padding: 25px 120px 0 120px;
`;

const BoundingBox = styled.div`
    border: 1px solid #cccccc;
    padding: 10px;
`;

const DisplayHeader = styled.div`
    font-size: 22px;
    font-weight: bold;
    color: #00a657;
    margin-bottom: 15px;
    width: 100%;
`;

export default AdminModule;