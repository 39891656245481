import { Listing } from '../../types/listing/listing';

const defaultListing:Listing = {
    "id": "",
    "externalId" : "",
    "miqId" : "",
    "isDeleted": false,
    "propertyId": "",
    "pending": false,
    "state": "",
    "published": false,
    "exclusivity": "",
    "authority": "",
    "editable": true,
    "propertyName": "",
    "propertyType": "",
    "propertyTypes": [],
    "propertyRecordName": "",
    "propertySubType": "",
    "propertyUseClass": "",
    "listingType": "",
    "officeId":null,
    "displayAddress": null,
    "street": "",
    "street2": "",
    "city": "",
    "stateOrProvince": "",
    "country": "",
    "postalCode": "",
    "energyRating": "",
    "salesOpportunity": "",
    "buildingDescriptionSingle": "",
    "buildingDescription": [],
    "tenancy": null,
    "alternatePostalAddresses": [],
    "locationDescription": [],
    "locationDescriptionSingle": "",
    "investmentDescription": [],
    "investmentDescriptionSingle": "",
    "status": "",
    "availableFrom": null,
    "syndicationFlag": false,
    "syndicationMarket": "",
    "website": "",
    "externalPropertyId": "",
    "externalPlatformId": null,
    "operator": "",
    "headlineSingle": "",
    "headline": [],
    "parkings": {
        "ratio": 0,
        "ratioPer": 0,
        "ratioPerUnit": "",
        "parkingDetails": []
    },
    "demoGraphics": {
        "uom": "",
        "interval1": 1,
        "interval2": 3,
        "interval3": 5,
        "cultureCode": "en-US", // "en-US" or "de-DE
        "demoGraphicsDetails": []
    },
    "auction":{
        "eventDate": null,
        "eventTime": null,
        "eventPlace": null,
        "eventTimeHrs": null,
        "eventTimeMinutes": null
    },
    "pointsOfInterests": [],
    "transportationTypes": [],
    "video": "",
    "walkThrough": "",
    "importedData": "",
    "aspects": [],
    "lng": 0,
    "lat": 0,
    "photos": [],
    "floorplans": [],
    "brochures": [],
    "brochureURL": "",
    "highlights": [],
    "epcGraphs": null,
    "teams": [],
    "externalPublishingState": [],
    "floors": null,
    "portfolio": null,
    "yearBuilt": null,
    "zoning": null,
    "specifications": {
        "leaseType": "",
        "leaseRateType": "",
        "measure": "",
        "leaseTerm": "",
        "minSpace": 0,
        "maxSpace": 0,
        "totalSpace": 0,
        "minPrice": 0,
        "maxPrice": 0,
        "salePrice": 0,
        "contactBrokerForPrice": false,
        "showPriceWithUoM": false,
        "plusSalesTax": false,
        "includingSalesTax": false,
        "currencyCode": "USD",
        "yieldPercentage": 0
    },
    "chargesAndModifiers": [],
    "propertySizes": [],
    "microMarkets" : [],
    "microMarket": "",
    "spaces": [],
    "spacesCount": 0,
    "contacts": [],
    "externalPublishUrl": "",
    "triggers": {
        addressChange: 0
    },
    "previewSearchApiEndPoint": "",
    "datasource" : {
        datasources: [],
        other: ""
    },
    "externalPreviewUrl": "", 
    "listingAssignment" : {
        assignedBy: "",
        assignmentFlag: false,
        assignedDate: new Date()
    },
    "dateCreated": new Date(),
    "dateUpdated": new Date(),
    "datePublished": new Date(),
    "dateListed": new Date()
}

export default defaultListing;