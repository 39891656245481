import { runWithAdal } from 'react-adal';
import { authContext } from './adalConfig';

const parent = window.parent as any;
if (parent && parent !== window && parent.location && parent.location.pathname && parent.location.pathname.startsWith("/v2")) {
  const checks = [
    /[\?|&|#]code=/,
    /[\?|&|#]error=/,
    /[\?|&|#]token=/,
    /[\?|&|#]id_token=/,
  ];

  const isResponse = (str: string) => {
    if (!str) {
      return false;
    }

    for (const check of checks) {
      if (str.match(check)) {
        return true;
      }
    }

    return false;
  }

  const message = isResponse(location.hash)
    ? "#" + encodeURIComponent(location.hash.substring(1))
    : "#" + encodeURIComponent(location.search);

  console.log(
    "Silent refresh iframe is posting to the parent application, message:",
    message
  );

  (window.opener || window.parent).postMessage(message, location.origin);
} else {
  const legacyRedirect = localStorage.getItem("legacy.redirect");
  if (legacyRedirect) {
    const hash = window.location.hash || "";
    window.location.href = legacyRedirect + hash;
  } else {
    const DO_NOT_LOGIN = false;
    runWithAdal(authContext, () => {
      const usr = authContext.getCachedUser();
      if (usr && window.analytics) {
        fetch(`https://cbre-uuid-fa.azurewebsites.net/api/uuid-generator?code=oPCx-ErmxkzsACG-xrPfmGTqQnEBy5HA5ELSUAlwOVK7AzFuwF7pIg==&email=${usr.userName}`)
          .then(response => {
            return response.text();
          })
          .then(data => {
            window.analytics.identify((data),
              {
                email: usr.userName
              });
          }).catch((error) => {
            console.log(error);
          });
        window.analytics.track("DEUserLogin", {
          user: usr.profile,
          siteId: localStorage.getItem("siteId")
        });
      }

      // eslint-disable-next-line
      require('./indexApp.tsx');

    }, DO_NOT_LOGIN);
  }
}