import React, { FC } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { userSelector } from '../../redux/selectors/system/user-selector';
import { User } from '../../types/state';
import AdminHeader from './admin-header';
import AdminWatermark from './modules/admin-watermark/admin-watermark';
import TestingDashboard from './modules/testing-dashboard/testing-dashboard';
import V2Link from './modules/v2-link/v2-link';
import AdminAccessRegion from './modules/admin-access/admin-access-region';

const AdminContainer: FC = (props) => {

    const user:User = useSelector(userSelector);
    
    return (
        <div>
            { user && user.isAdmin &&  
                <div>
                    <AdminHeader/>
                    <Container>
                        {/* <TestingDashboard foo="bar"/> */}
                        <AdminWatermark/>
                        <V2Link />
                        <AdminAccessRegion/>
                    </Container>
                </div>   
            }
            { !user || !user.isAdmin && <div>Sorry, these are not the droids you are looking for...</div>}
        </div>
    )
}

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export default AdminContainer;

