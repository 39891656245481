
import React, { FC, useContext, useState, useCallback } from 'react'
import { Col, Row } from 'react-styled-flexboxgrid'
import FormSelect, { FormSelectProps } from '../../../components/form-select/form-select';
import FormInput, { FormInputProps } from '../../../components/form-input/form-input'
import SectionHeading from "../../../components/section-heading/section-heading";
import styled from 'styled-components';
import { Config, AuctionConfig, AvailableFromConfig } from '../../../types/config/config';
import { generateKey } from '../../../utils/keys';
import { Auctions } from '../../../types/listing/auctions';
import GLField from '../../../components/form/gl-field';
import GLForm from '../../../components/form/gl-form';
import { convertValidationJSON } from '../../../utils/forms/validation-adapter';
import { FormContext } from '../../../components/form/gl-form-context';
import FormDateField, { FormDateFieldProps } from '../../../components/form-date-field/form-date-field';


interface Props {
    config: Config,
    auctionConfig: AuctionConfig,
    auctionDetails: Auctions,
}

const Auction: FC<Props> = (props) => {

    const { auctionDetails, auctionConfig, config } = props;
    if (auctionDetails.eventTime) {
        auctionDetails.eventTimeHrs = auctionDetails.eventTime.split(':')[0];
        auctionDetails.eventTimeMinutes = auctionDetails.eventTime.split(':')[1];
    }
    const [data, setData] = useState<Auctions>(auctionDetails);
    const validations = {};
    const formControllerContext = useContext(FormContext);


    const changeHandler = (values: any) => {
        const temp: Auctions = data;
        temp.eventPlace = values.eventPlace;
        temp.eventDate = values.eventDate;
        temp.eventTime = `${values.eventTimeHrs ? values.eventTimeHrs : '00'}:${values.eventTimeMinutes ? values.eventTimeMinutes : '00'}`;
        formControllerContext.onFormChange({ auction: temp });
    }

    const getEventDate = () => {
        if (config && config.auction.EventDate) {
            const fieldConfig: AvailableFromConfig = config.auction.EventDate;
            return (
                <Col xs={3} id="eventDate">
                    <GLField<FormDateFieldProps> name="eventDate" use={FormDateField} {...fieldConfig} />
                </Col>
            );
        }
        return <></>;
    }
    return (
        <AuctionContainer>
            <GLForm initVals={auctionDetails}
                validationAdapter={convertValidationJSON}
                validationJSON={validations}
                changeHandler={changeHandler}
                key={generateKey()}>
                <Row>
                    <Col id="Auction" xs={12}><SectionHeading>{auctionConfig.label}</SectionHeading></Col>
                </Row>
                <Row>
                    <Col xs={3}>
                        <GLField<FormInputProps> name="eventPlace" placeholder="Event Place"
                            label="Event Place" use={FormInput} maxLength={40} />
                    </Col>

                    {getEventDate()}

                    <Col xs={4} id="eventTime">
                        <div style={{ width: '115px' }}>
                            <GLField<FormSelectProps> defaultValue={auctionDetails.eventTimeHrs} use={FormSelect} options={auctionConfig.hours} disabled={false} name="eventTimeHrs" label='Event Time' />
                        </div>
                        <div style={{ marginLeft: '10px', marginTop: '65px' }}>
                            :
                        </div>
                        <div style={{ marginLeft: '10px', marginTop: '27px', width: '115px' }}>
                            <GLField<FormSelectProps> defaultValue={auctionDetails.eventTimeMinutes} use={FormSelect} options={auctionConfig.minutes} disabled={false} name="eventTimeMinutes" />
                        </div>
                    </Col>
                </Row>
            </GLForm>
        </AuctionContainer>
    )
}

const AuctionContainer = styled.div`
    #eventTime{
        display: flex !important;
    }
`
export default Auction;