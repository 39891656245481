export const RoutePaths = {
    listings: '/',
    listingEntry: '/le/:id',
    createListing: '/le',
    teams: '/myteams',
    admin: '/admin',
    miqImport: '/miq/import',
    miqExport: '/miq/create/:id',
    insights: '/insights/:id',
    insightsRoot: '/insights',
    brokerImageUpload: '/brokerimageupload',
    userAccess:"/userAccess"
};  