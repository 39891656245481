
import React, { FC, useContext, useState, useCallback } from 'react'
import { Col, Row } from 'react-styled-flexboxgrid'
import FormSelect, { FormSelectProps } from '../../../components/form-select/form-select';
import FormInput, { FormInputProps } from '../../../components/form-input/form-input'
import SectionHeading from "../../../components/section-heading/section-heading";
import styled from 'styled-components';
import MultiDropdown from './multi-dropdown/multi-dropdown';
import { Config, DemographicsConfig } from '../../../types/config/config';
import StyledButton from '../../../components/styled-button/styled-button';
import { generateKey } from '../../../utils/keys';
import { DemoGraphics, DemoGraphicsDetails } from '../../../types/listing/demographics';
import { Option } from '../../../types/common/option';
import GLField from '../../../components/form/gl-field';
import GLForm from '../../../components/form/gl-form';
import { convertValidationJSON } from '../../../utils/forms/validation-adapter';
import { FormContext } from '../../../components/form/gl-form-context';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";


interface Props {
    config: Config,
    demographicsConfig: DemographicsConfig,
    demoGraphics: DemoGraphics,
}

const Demographics: FC<Props> = (props) => {

    const { demoGraphics, demographicsConfig, config } = props;
    const [data, setData] = useState<DemoGraphics>(demoGraphics);

    const validations = {};
    const [, updateState] = useState({});
    const forceUpdate = useCallback(() => updateState({}), []); // @ts-ignore
    const formControllerContext = useContext(FormContext);
    const categoryOptions: Option[] = config.demographics.categoryOptions;
    const [unusedOptions, setUnusedOptions] = useState<Option[]>(categoryOptions.filter(option =>
        !(data.demoGraphicsDetails ? data.demoGraphicsDetails.map(detail => detail.category) : []).includes(option.value)
    ));

    /* if we have lease type, we need to ensure we set the value if it's not set in our data */
    if (!demoGraphics.uom || demoGraphics.uom.trim().length === 0) {
        if (demographicsConfig && demographicsConfig.unitOfMeasure && demographicsConfig.unitOfMeasure.defaultValue) {
            demoGraphics.uom = demographicsConfig.unitOfMeasure.defaultValue;
        }
    }

    const clearFocus = () => {
        const el: any = document.querySelector(':focus');
        if (el) {
            el.blur();
        }
    }

    const addRow = (index: number) => {
        const temp: DemoGraphics = data;
        temp.demoGraphicsDetails.push({
            value1: null,
            value2: null,
            value3: null,
            category: '',
            usageType: ''
        });

        setData(temp);
        ReloadUnusedOptions(temp);
        forceUpdate();
    }

    const deleteRow = (index: number) => {
        const temp: DemoGraphics = data;
        temp.demoGraphicsDetails.splice(index, 1);
        setData(temp);
        ReloadUnusedOptions(temp);
        forceUpdate();
    }

    const demoGraphicsChangeHandler = (values: any, index: number) => {
        const temp: DemoGraphics = data;
        const oldCategory = temp.demoGraphicsDetails[index].category;

        temp.demoGraphicsDetails[index] = {
            category: values.category,
            value1: Number(values.value1),
            value2: Number(values.value2),
            value3: Number(values.value3),
            usageType: ''
        };
        formControllerContext.onFormChange({ demoGraphics: temp });

        if (oldCategory !== values.category) {
            ReloadUnusedOptions(temp);
        }
    }

    const changeHandler = (values: any) => {
        const temp: DemoGraphics = data;
        temp.uom = values.uom;
        temp.interval1 = values.interval1;
        temp.interval2 = values.interval2;
        temp.interval3 = values.interval3;

        temp.demoGraphicsDetails = values.demoGraphicsDetails || [];
        formControllerContext.onFormChange({ demoGraphics: temp });
    }

    const reorder = (list: any, startIndex: number, endIndex: number) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    }

    const onDragEnd = (value: any) => {
        if (!value.destination) {
            return;
        } else if (value.source.index === value.destination.index) {
            return;
        }

        let tempDemographicDetails: any = [];

        const sourceIndex: number = value.source.index;
        const destination: number = value.destination.index;
        tempDemographicDetails = reorder(data.demoGraphicsDetails, sourceIndex, destination);
        data.demoGraphicsDetails = tempDemographicDetails;

        setData(data);
        forceUpdate();
    }

    const ReloadUnusedOptions = (temp: DemoGraphics) => {
        const selectedOptions = temp.demoGraphicsDetails.map(detail => detail.category);
        const newUnusedOptions = categoryOptions.filter(option => !selectedOptions.includes(option.value));
        setUnusedOptions(newUnusedOptions);
    }

    const loadOption = (demographicData: DemoGraphicsDetails): Option[] => {
        let selectedOptions = [...unusedOptions]; // tslint:disable-line
        if (demographicData && demographicData.category !== "") {
            const selectOption = categoryOptions.find(option => option.value === demographicData.category);
            if (selectOption) {
                selectedOptions.unshift(selectOption);
            }
        }
        return selectedOptions;
    }

    return (
        <DemographicsContainer>
            <GLForm initVals={demoGraphics}
                validationAdapter={convertValidationJSON}
                validationJSON={validations}
                changeHandler={changeHandler}
                key={generateKey()}>
                <Row>
                    <Col id="demoGraphics" xs={12}><SectionHeading>{demographicsConfig.label}</SectionHeading></Col>
                </Row>
                <Row>
                    <Col xs={3}>
                        <GLField<FormSelectProps>
                            use={FormSelect}
                            key='demographics_uom'
                            isClearable={false}
                            defaultValue={demoGraphics.uom}
                            options={demographicsConfig.unitOfMeasure.options}
                            disabled={false}
                            name="uom"
                            label='Unit of Measurement' />
                    </Col>
                    <Col xs={3}>
                        <GLField<FormInputProps> key='demographics_interval1' suffix={demoGraphics.uom} defaultValue={demoGraphics.interval1} numericOnly={true} acceptDecimals={true} use={FormInput} name="interval1"
                            disabled={false} label='Distance' />
                    </Col>
                    <Col xs={3}>
                        <GLField<FormInputProps> key='demographics_interval2' suffix={demoGraphics.uom} defaultValue={demoGraphics.interval2} numericOnly={true} acceptDecimals={true} use={FormInput} name="interval2"
                            disabled={false} label='&nbsp;' />
                    </Col>
                    <Col xs={3}>
                        <GLField<FormInputProps> key='demographics_interval3' suffix={demoGraphics.uom} defaultValue={demoGraphics.interval3} numericOnly={true} acceptDecimals={true} use={FormInput} name="interval3"
                            disabled={false} label='&nbsp;' />
                    </Col>
                </Row>

                <DragDropContext onDragEnd={(event: any) => { onDragEnd(event) }} >
                    <Droppable droppableId={String(Date.now())}>
                        {provided => ( // tslint:disable-line
                            <div ref={provided.innerRef} {...provided.droppableProps}>
                                {data.demoGraphicsDetails && Array.isArray(data.demoGraphicsDetails) && data.demoGraphicsDetails.map((demographicData: DemoGraphicsDetails, index: number) => (
                                    <div key={index}>
                                        <Draggable draggableId={"category" + index} index={index} key={index} isDragDisabled={false}>
                                            {provided => ( // tslint:disable-line
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <MultiDropdown name={'demographic_details_' + index} key={generateKey()}
                                                        categoryOptions={loadOption(demographicData)}
                                                        interval={1}
                                                        index={index} deleteRow={deleteRow}
                                                        value={demographicData} changeHandler={demoGraphicsChangeHandler} order={index}
                                                        label={demographicsConfig.label ? demographicsConfig.label : ' '}
                                                        currencies={config.currencies ? config.currencies.options : []}
                                                    />
                                                </div>
                                            )}
                                        </Draggable>
                                    </div>
                                ))}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                <StyledButton name={'add demographics'} onMouseOver={clearFocus} onClick={(unusedOptions.length < 1) ? undefined : addRow} style={{ marginTop: '25px', opacity: (unusedOptions.length < 1) ? 0.5 : 1 }} styledSpan={true} buttonStyle="2" ><span style={{ fontSize: "18px" }}>+</span>&nbsp;&nbsp; Add Demographics</StyledButton>
            </GLForm>
        </DemographicsContainer>
    )
}

const DemographicsContainer = styled.div`
    #DemoGraphics{
        h2{
            margin-top:45px;
            margin-bottom:0;
            color: #8E9A9D !important;
            font-size:18px;
        }
    }
`
export default Demographics;