import { Config } from '../../types/config/config';
import { Option } from '../../types/common/option';


export const findOptions = (type: string, config: Config, propertyType: string): Option[] => {

    let options: Option[] = [];
    const configType = type === 'epcrating' ? config.epcRating : config.tenancy

    if(!config || !propertyType || propertyType.length === 0 || !configType){
        return options;
    }

    Object.keys(configType).forEach(key => {
        if(config && configType && configType[key]){
            if(configType[key].values && configType[key].values.indexOf(propertyType) !== -1){
                options = configType[key].options;
            }
        }
    });

    return options;
}