
import React, { FC, useContext } from 'react'
import GLForm from '../../../components/form/gl-form';
import { convertValidationJSON } from '../../../utils/forms/validation-adapter';
import GLField from '../../../components/form/gl-field';
import { Col, Row } from 'react-styled-flexboxgrid'
import DraggableMultiLangList from '../../../components/draggable-list/draggable-multilang-list'
import SectionHeading from "../../../components/section-heading/section-heading";
import { FormContext } from '../../../components/form/gl-form-context';
import { Listing } from '../../../types/listing/listing';
import { ManualError } from '../validations/published';
import styled from 'styled-components';

interface Props {
    listing: Listing, 
    label: string,
    error: ManualError,
    helpText: string
}

const Highlights: FC<Props> = (props) => {

    const { listing, label, error, helpText } = props;
    
    const init:object = {};
    const validations = {};
    
    // value change interceptor
    const formControllerContext = useContext(FormContext);

    const changeHandler = (values:any) => {
        formControllerContext.onFormChange(values);
    }

    const DisplayError = () => {
        return (
            <Row>
                <Col sm={12} style={{ marginLeft: '8px' }}><ErrorText>{error.message}</ErrorText></Col>
            </Row>
        )
    }

    return (
        <GLForm initVals={init}
            validationAdapter={convertValidationJSON}
            validationJSON={validations}
            changeHandler={changeHandler}>
            <Row>
                <Col xs={12} id="highlights" style={{ marginTop: '25px', display: 'flex' }} ><SectionHeading error={error && error.error}>{label}</SectionHeading>
                </Col>
                {helpText &&
                    <StyledSubtext>({helpText})</StyledSubtext>
                }
                {error && error.error && <DisplayError />}
                <Col xs={12}>
                    <GLField name="highlights" items={listing.highlights} use={DraggableMultiLangList}/>
                </Col>
            </Row>
        </GLForm>
    )
}
const ErrorText = styled.div`
    color: ${props => (props.theme.colors ? props.theme.colors.error : 'red')}; 
`;
const StyledSubtext = styled.div`
  color: #9EA8AB;
  margin-left: 0.5em;
  font-family: "futura PT Book italic";
  font-size: 14px;
`;

export default Highlights