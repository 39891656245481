export const taxTypes: any[] = [
    {
        label: "Don't Display",
        value: "None",
        order: 1
    },
    {
        label: "Plus Sales Tax",
        value: "PlusSalesTax",
        order: 2
    },
    {
        label: "Includes Sales Tax",
        value: "IncludingSalesTax",
        order: 3
    }
];

export const vatTaxTypes: any[] = [
    {
        label: "Don't Display",
        value: "None",
        order: 1
    },
    {
        label: "Plus VAT",
        value: "PlusVAT",
        order: 2
    }
];