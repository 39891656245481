import React, { FC, useEffect, useState } from 'react';
import { Col, Grid, Row } from 'react-styled-flexboxgrid';
import styled from 'styled-components';
import { InsightsRecord } from '../../../types/insights/insights-record';
import { Listing } from '../../../types/listing/listing';
import DefaultPropertyImage from '../../../assets/images/png/property-default.png';
import { Contact } from '../../../types/listing/contact';
import ImageAvatar, { ImageAvatarSize, PlaceholderSize } from '../../../components/image-avatar/image-avatar';
import { generateInitials } from '../../../components/contact-card/util/generate-initials';
import { postData } from '../../../api/glAxios';
import { getAnalyticsByPropertyId, getLeadsReport } from '../../../api/glQueries';
import { useDispatch } from 'react-redux';
import { ActionPayload } from '../../../types/common/action';
import { MainMessageType, MainMessaging } from '../../../types/state';

export interface Props {
    record: InsightsRecord,
    leadsClick: any
};

// constants
export const SET_INSIGHTS_LOADING = 'SET_INSIGHTS_LOADING';

// types
export type SetInsightsLoadingAction = ActionPayload<MainMessaging> & {
    type: typeof SET_INSIGHTS_LOADING
};

// functions
export const setInsightsLoading = (payload: MainMessaging): SetInsightsLoadingAction => ({
    type: SET_INSIGHTS_LOADING,
    payload
});


const InsightsPropertyOverview: FC<Props> = (props) => {
    const { record, leadsClick } = props;
    const listing: Listing = record.listing;
    const [insightData, setInsightData] = useState<any[]>([]);
    const dispatch = useDispatch();

    const generateCoverImage = () => {
        let src: any = DefaultPropertyImage;
        if (listing && listing.photos) {
            const photoArr = [...listing.photos];
            photoArr.sort((a: any, b: any) => (a.primary ? a : b));
            if (photoArr.length > 0) {
                src = photoArr[0].url
            }
        }
        return (
            <CoverImage src={src} alt="Cover Image" />
        );
    }

    useEffect(() => {
        if (listing && listing.externalId) {
            postData(getAnalyticsByPropertyId(parseInt(listing.id, 10)))
                .then((response: any) => {
                    if (response.data && response.data.getAnalyticsByPropertyId) {
                        const data = mapToAnalyticsObject(response.data.getAnalyticsByPropertyId);
                        setInsightData(data);
                    } else {
                        setInsightData([]);
                        dispatch(setInsightsLoading({ show: true, type: MainMessageType.ERROR, message: "Unauthorized Access", home: true }));
                    }
                });
        }
        else {
            setInsightData([]);
        }
    }, []);


    const mapToAnalyticsObject = (analyticsData: any) => {
        const analyticsObj = [
            { name: "Lead Generated", count: 0 },
            { name: "Unique Visitors", count: 0 },
            { name: "Listing Views", count: 0 },
            { name: "Share Count", count: 0 }
        ];

        if (analyticsData != null) {
            analyticsObj[0].count = analyticsData.leadCount || 0;
            analyticsObj[1].count = analyticsData.newUserCount || 0;
            analyticsObj[2].count = analyticsData.pDPClicks || 0;
            analyticsObj[3].count = analyticsData.shareCount || 0;
        }

        return analyticsObj;
    }

    const generateAddressLine2 = (): string => {
        let addressLine2: string = "";
        if (listing.city && listing.city.length > 0) { addressLine2 += listing.city; }
        if (listing.stateOrProvince && listing.stateOrProvince.length > 0) {
            if (addressLine2.length > 0) {
                addressLine2 += ", ";
            }
        }
        if (listing.postalCode && listing.postalCode.length > 0) {
            if (addressLine2.length > 0) {
                addressLine2 += " ";
            }
            addressLine2 += listing.postalCode;
        }


        return addressLine2;
    }

    const getDaysOnMarket = (): string => {
        let val: string = "---";
        if (listing.state && listing.state.toLowerCase() === "published") {
            if (listing.dateListed) {
                const timeDiff = Math.abs(new Date().getTime() - listing.dateListed.getTime());
                val = Math.ceil(timeDiff / (1000 * 60 * 60 * 24)).toString();
            }
            else if (listing.datePublished) {
                const timeDiff = Math.abs(new Date().getTime() - listing.datePublished.getTime());
                val = Math.ceil(timeDiff / (1000 * 60 * 60 * 24)).toString();
            }
        }
        return val;
    }

    const getDateListed = (): string => {
        let val: string = "---";
        if (listing.state && listing.state.toLowerCase() === "published") {
            if (listing.dateListed) {
                val = listing.dateListed.toLocaleDateString();
            }
            else if (listing.datePublished) {
                val = listing.datePublished.toLocaleDateString();
            }
        }
        return val;
    }

    const getLastUpdated = (): string => {
        let val: string = "---";
        if (listing.datePublished) {
            val = listing.datePublished.toLocaleDateString();
        }
        return val;
    }

    const getPropertyId = (): string => {
        return listing.externalId || "";
    }

    const getPropertyAnalytics = () => {
        if (insightData && insightData.length > 0) {
            return (
                insightData.map((option: any, i) => {
                    return (
                        <>
                            <AnalyticsContainer key={'propertyAnalytics ' + option.name}>
                                <SectionHeading>{option.name}</SectionHeading>
                                <AnalyticsDivider>
                                    <AnalyticsDataContainer>{option.name === "Lead Generated" ? (
                                        <LeadContainer onClick={leadsClick}>{option.count}</LeadContainer>) : option.count}</AnalyticsDataContainer>
                                </AnalyticsDivider>
                            </AnalyticsContainer>
                        </>
                    )
                }))
        }
        else {
            return (<NoDataFound>No Data Found</NoDataFound>)
        }
    }

    return (
        <Container>
            {listing &&
                <Grid>
                    <Row>
                        { /* image and view listing link column */}
                        <Col sm={4}>
                            <Row>
                                { /* property cover image */}
                                <Col sm={6}>
                                    <CoverImageContainer>{generateCoverImage()}</CoverImageContainer>
                                </Col>
                            </Row>
                        </Col>
                        { /* property information column */}
                        <Col sm={5} style={{ marginLeft: '10px' }}>
                            { /* property name header */}
                            <Row>
                                <Col sm={12}>
                                    <PropertyHeader>{listing.propertyName}</PropertyHeader>
                                </Col>
                            </Row>
                            { /* address lines */}
                            <Row>
                                <Col sm={12}>
                                    <AddressLine>{listing.street}</AddressLine>
                                    <AddressLine>{generateAddressLine2()}</AddressLine>
                                </Col>
                            </Row>
                            { /* property information */}
                            <InfoRow>
                                <Col sm={4}>
                                    <InfoHeader>Days on Market</InfoHeader>
                                    <InfoValue>{getDaysOnMarket()}</InfoValue>
                                </Col>
                                <Col sm={4}>
                                    <InfoHeader>Date Listed</InfoHeader>
                                    <InfoValue>{getDateListed()}</InfoValue>
                                </Col>
                                <Col sm={4}>
                                    <InfoHeader>Last Published</InfoHeader>
                                    <InfoValue>{getLastUpdated()}</InfoValue>
                                </Col>
                            </InfoRow>
                            { /* property information */}
                            <InfoRow>
                                <Col sm={12}>
                                    <InfoHeader>Property Id</InfoHeader>
                                    <InfoValue>{getPropertyId()}</InfoValue>
                                </Col>
                            </InfoRow>
                        </Col>
                        { /* brokers */}
                        <Col sm={2}>
                            {listing.contacts &&
                                <BrokerSection>
                                    {
                                        listing.contacts.map((broker: Contact) => {
                                            return <BrokerRow key={"broker-row" + broker.contactId}>
                                                <Col>
                                                    <BrokerContainer>
                                                        <ImageAvatar key={"ia_" + broker.contactId}
                                                            placeholderSize={PlaceholderSize.SMALL}
                                                            placeholder={generateInitials(broker)}
                                                            avatarSize={ImageAvatarSize.SMALL}
                                                            imageURL={broker.avatar}
                                                            allowUpload={false} />
                                                        <BrokerName>{broker.firstName + " " + broker.lastName}</BrokerName>
                                                    </BrokerContainer>
                                                </Col>
                                            </BrokerRow>;
                                        })
                                    }
                                </BrokerSection>
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            {insightData && insightData.length > 0 && getPropertyAnalytics()}
                        </Col>
                    </Row>
                </Grid>
            }
        </Container>
    );
};

const LeadContainer = styled.a`
    cursor: pointer;
    text-decoration: underline;
    color:green;
`;

const Container = styled.div`
    width: 100%;
    padding: 30px 0 30px 0;
`;

const AnalyticsContainer = styled.div`
    text-align: center;
    margin-top:20px;
    float: left;
    margin-right: 10px;
    width: 20%;
    padding: 12px 10px 1px;
    border: 1px solid rgb(179, 179, 179);
    border-radius: 5px;
    background-color: rgb(245, 247, 247);
    box-shadow: rgba(153, 153, 153, 0.3) 7.07107px 7.07107px 10px 3px;
    hegiht: 50px;
`;

const NoDataFound = styled.div`
    height: 40px
    text-align: center;
    margin-top:20px;
    float: left;
    margin-right: 10px;
    width: 100%;
    padding: 12px 10px 1px;
    border: 1px solid rgb(179, 179, 179);
    border-radius: 5px;
    background-color: rgb(245, 247, 247);
    box-shadow: rgba(153, 153, 153, 0.3) 7.07107px 7.07107px 10px 3px;
    hegiht: 50px;
`;

const AnalyticsDivider = styled.div`
    border-top: 2px solid rgb(23, 232, 143);
    margin-left: -10px;
    margin-right: -10px;
    vertical-align: bottom;
    text-align: center;
    `;

const AnalyticsDataContainer = styled.div`
    font-weight: bold;
    font-size: x-large;
    margin-top: 25px;
    height: 50px;
`;

const CoverImageContainer = styled.div``;

const CoverImage = styled.img`
    width: 350px;
`;

const PropertyHeader = styled.div`
    font-size: 36px;
    font-weight: 400;
    color: #003F2D;
`;

const AddressLine = styled.div`
    font-size: 18px;
    font-weight: 600;
    color: #003F2D;
`;

const InfoRow = styled(Row as any)`
    margin-top: 30px;
`;

const InfoHeader = styled.div`
    font-size: 14px;
    font-weight: 400;
`;

const InfoValue = styled.div`
    font-size: 21px;
    font-weight: 700;
`;

const BrokerSection = styled.div`
    border-left: 1px solid #ccc;
    height: 100%;
`;

const BrokerRow = styled(Row as any)``;

const BrokerContainer = styled.div`
    display: flex;
    align-items: center;
    border-bottom: 1px solid #cccccc;
    width: 250px;
    padding: 15px;
    margin-left: 10px;
`;

const BrokerName = styled.div`
    color: #003F2D;
    font-size: 18px;
    font-weight: 600;
    margin-left: 10px;
`;

const SectionHeading = styled.h4`
  font-family:'inherit';
  font-family: 'helvetica';	
  letter-spacing: .01rem;
  text-transform: capitalize;
  margin: 0px 0px 8px 0px;
`;

export default InsightsPropertyOverview;