import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Select from 'react-select';
import SiteIcon from './common/header/site-icon';
import { Col, Row } from 'react-styled-flexboxgrid';
import { loadConfig } from '../redux/actions/system/load-config-details';
// import { Region as CountryRegion } from '../types/config/config';
import StyledButton from '../components/styled-button/styled-button';
import { configDetailsSelector } from '../redux/selectors/system/config-details-selector';
import { getAccessToRegion, getAllRegion } from '../redux/actions/user/new-user';
import { Redirect } from 'react-router';
import { push } from 'connected-react-router';


interface Region {
    value: string;
    order: number;
    regionId: string;
    label: any;
}


const UserAccess: FC = (props) => {


    const [regions, setRegions] = useState<Region[]>([]);
    const [initialValue, setIntialValue] = useState({} as Region);
    const [skipUserAccess, setSkipUserAccess] = useState(true);
    const dispatch = useDispatch();


    useEffect(() => {
        getAllRegion(skipUserAccess).then((result: any) => {
            if (result.data.regions) {
                let regionsData = result.data.regions;
                const params = new URLSearchParams(window.location.search);
                const country = params.get('country');
                const regionCountry = regionsData.filter((obj:any) => obj.homeSiteID === country);

                if (regionCountry.length !== 0) {
                    regionsData = regionCountry;
                }

                setRegions(regionsData);
                const defaultCountry = regionsData[0];
                dispatch(loadConfig(defaultCountry.homeSiteID, true));

                const defaultRegion: Region = {
                    value: defaultCountry.homeSiteID,
                    order: 0,
                    regionId: defaultCountry.iD,
                    label:
                        < StyledOption >
                            <StyledIcon siteId={defaultCountry.homeSiteID} />
                            {defaultCountry.name}
                        </StyledOption >
                }
                setIntialValue(defaultRegion);

            }
        }).catch((error: string) => {
            // setMessageText(error);
        });
    }, [])




    const options = regions.map((region: any, index: number) => {
        return {
            value: region.homeSiteID,
            order: index,
            regionId: region.iD,
            label:
                <StyledOption>
                    <StyledIcon siteId={region.homeSiteID} />
                    {region.name}
                </StyledOption>
        };
    })


    const getAccess = () => {
        getAccessToRegion(initialValue.regionId).then((result: any) => {
            if (result.data.addSelfToRegion) {
                window.location.href = "/"

            }
        }).catch((error: string) => {
            console.log(error);
        });
    }

    const selected = (value: any) => {
        const siteId = value.value;
        const selectedRegion = options.find((option: any) => {
            return option.value === siteId;
        })
        setIntialValue(selectedRegion!);
        dispatch(loadConfig(selectedRegion!.value, true));
    }

    return (
        <div>
            <StyledListingsHeader>
                <StyledInnerContainer>
                    <StyledTitle style={{ float: "left", 'marginRight': '15px', 'marginTop': '8px', 'marginBottom': '-8px' }}>Data Entry</StyledTitle>
                </StyledInnerContainer>
            </StyledListingsHeader>
            <MainContainer style={{ marginTop: '20px', marginBottom: '260px' }}>
                <span>Please Select the country</span>
                <Row>
                    <Col sm={4}>
                        <StyledContainer>
                            <StyledSelect
                                id="regionSelect" // used by test automation suite                            
                                onChange={selected}
                                options={options}
                                value={initialValue}
                            />
                        </StyledContainer>
                    </Col>
                    <Col sm={2} style={{ 'marginTop': '4px' }}>
                        <StyledButton type="button" onClick={() => getAccess()}>
                            Enter
                        </StyledButton>
                    </Col>
                </Row>

            </MainContainer>

        </div>
    )
}


const StyledListingsHeader = styled.div`
    background: #006A4D;
    overflow:auto;
    padding:12px 0;
    font-family: ${props => (props.theme.font ? props.theme.font.primary : 'sans-serif')};
    position:sticky;
    top:0;
    z-index:100;
    height: 37px;
    box-shadow: 0 4px 6px rgba(204,204,204,0.12), 0 2px 4px rgba(204,204,204,0.24);
`;

const StyledInnerContainer = styled.div`
    max-width: ${props => props.theme.container.maxWidth};
    width: ${props => props.theme.container.width};
    margin:0 auto;
`;

const StyledTitle = styled.h1`
    display: inline-block;
    color:white;
    float:left;
    text-transform:uppercase;
    font-size: 1.3em;
    font-family: ${props => (props.theme.font ? props.theme.font.bold : 'sans-serif')};
    padding:0px;
`;

const MainContainer = styled.div`
    font-family: ${props => (props.theme.font ? props.theme.font.primary : 'sans-serif')};
    max-width: ${props => props.theme.container.maxWidth};
    width: ${props => props.theme.container.width};
    margin:0 auto;
`;

const StyledContainer = styled.div`
    width: auto;
    z-index: 5;
    position: relative;
`;

const StyledSelect = styled(Select)`
> div {
   border-radius:0px;
   heght:42px;
}
    width: auto;
    margin-top: 5px;
    color: #666;
`;

const StyledOption = styled.div`
    display: flex;
    align-items: center;
    font-family: "Futura Md BT";
    white-space: normal;
`;

const StyledIcon = styled(SiteIcon)`
    margin-right: 15px;
`

export default UserAccess;