import { ActionPayload } from '../../../types/common/action';
import { State, User } from '../../../types/state';
import { postData } from '../../../api/glAxios';
import { defaultRegionID, userRoles } from '../../../api/glQueries';
import { Region } from '../../../types/config/config';


// constants
export const SET_ADMIN = 'SET_ADMIN';

// types
export type SetAdminAction = ActionPayload<User> & {
    type: typeof SET_ADMIN
};

export const setAdminLoaded = (payload:User) : SetAdminAction => ({
    type: SET_ADMIN,
    payload
});

export async function checkAdmin(state:State, regions: Region) {

    const user = state.system.user;
    const region = state.mapping.selectedCountry || defaultRegionID;

    let userData:any;

    if(user && (!user.isAdmin || !user.isAdmin === null)){

        return await postData(userRoles(region))
            .then((response: any) => {

                if(response && response.data.isAdmin !== null){
                    userData = Object.assign({},user);
                    userData.regions = regions;
                    userData.isAdmin = response.data.userRoles.isAdmin;
                    userData.isExternalSystemBrokerAvatarUploadRole = response.data.userRoles.isExternalSystemBrokerAvatarUploadRole;
                    if(!userData.isAdmin && userData.isExternalSystemBrokerAvatarUploadRole){
                        userData.isOnlyExternalSystemBrokerAvatarUploadRole = true;
                    }
                    return userData;
                }else{
                    return userData;
                }
        }); 
    }else {
        return userData;
    }  
}