import React, { FC, useContext, useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import StyledButton from '../../components/styled-button/styled-button';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import BulkUploadButton from '../../components/bulk-upload-button/bulk-upload-button';
import { useSelector, useDispatch } from 'react-redux';
import { searchTextSelector } from '../../redux/selectors/pagedListings/search-text-selector'
import { searchChanged } from '../../redux/actions/pagedListings/update-search-text';
import { GLAnalyticsContext } from '../../components/analytics/gl-analytics-context';
import debounce from 'debounce';
import { featureFlagSelector } from '../../redux/selectors/featureFlags/feature-flag-selector';
import { FeatureFlags, MainMessageType, State } from '../../types/state';
import { axiosDownloadReport } from '../../api/glAxios';
import { defaultRegionID } from '../../api/glQueries';
import { regionIdSelector } from '../../redux/selectors/system/config-region-id-selector'
import { clearMessage, setMainMessage } from '../../redux/actions/system/set-main-message';
import { configSelector } from '../../redux/selectors/system/config-selector';
import { allContactsSelector } from '../../redux/selectors/contacts/all-contacts-selector';
import { Contact } from '../../types/listing/contact';

interface Props {
    showSearch: boolean;
}

const ListingHeader: FC<Props> = (props) => {

    const { showSearch } = props;

    const dispatch: any = useDispatch();
    const analytics = useContext(GLAnalyticsContext);
    const searchText: string = useSelector(searchTextSelector);
    const regionId: string | undefined = useSelector(regionIdSelector);
    const config = useSelector(configSelector);
    const brokers = useSelector(allContactsSelector);
    const [value, setValue] = useState('');
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [showDropdown, setShowDropdown] = useState(false);
    const [searchBrokerOption, setSearchBrokerOption] = useState(false);
    const [highlightedIndex, setHighlightedIndex] = useState(-1);

    // feature flags
    const featureFlags: FeatureFlags = useSelector(featureFlagSelector);
    const miqImport: boolean = featureFlags.miqImportFeatureFlag && !config.featureFlags.hideMiqImportFeatureFlag;
    const bulkUpload: boolean = config.featureFlags.hideBulkUploadFeatureFlag && config.featureFlags.hideBulkUploadFeatureFlag;
    const searchBroker: boolean = config.featureFlags && config.featureFlags.searchBrokerFeatureFlag !== false;

    const handleInput = (e: any) => {
        setValue(e.target.value);
        if (e.target.value) {
            setHighlightedIndex(-1);
            setShowDropdown(true);
        } else {
            setShowDropdown(false);
        }
    };

    useEffect(() => {
        const hideDropdown = () => {
            setShowDropdown(false);
        };

        window.addEventListener('scroll', hideDropdown);
        
        return () => {
            window.removeEventListener('scroll', hideDropdown);
        };
    }, []);

    useEffect(() => {
        if (value === "") {
            searchProperty("");
        }
    }, [value]);

    const handleInputSearch = (e: any) => {
        if (e.key === 'Enter' && !searchBrokerOption) {
            searchProperty(value);
        }
    };

    const searchProperty = (keyword: string) =>
    {
        dispatchSearch(keyword);
        setShowDropdown(false);
    }

    const handleOptionClick = (e: any) => {
        setValue(e.email);
        if (inputRef.current) {
            inputRef.current.value = e.email;
        }
        searchProperty(e.email);
    };

    const matchingBrokers: Contact[] | false = brokers && brokers.length > 0 && brokers.filter(broker =>
        (broker.email != null && broker.email.toLowerCase().includes(value.replace(" ", ".").toLowerCase()))
        || (broker.firstName != null && broker.firstName.toLowerCase().includes(value.toLowerCase()))
        || (broker.lastName != null && broker.lastName.toLowerCase().includes(value.toLowerCase()))
    ).slice(0, 6);

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {

            if (event.key === 'ArrowDown') {
                event.preventDefault();
                if (Array.isArray(matchingBrokers)) {
                    setHighlightedIndex((prev) => (prev + 1) % matchingBrokers.length);
                    setSearchBrokerOption(true);
                }
            } else if (event.key === 'ArrowUp') {
                event.preventDefault();
                if (Array.isArray(matchingBrokers)) {
                    setHighlightedIndex((prev) => (prev - 1 + matchingBrokers.length) % matchingBrokers.length);
                    setSearchBrokerOption(true);
                }
            }
            if (event.key === 'Enter' && searchBrokerOption) {
                event.preventDefault();
                if (Array.isArray(matchingBrokers) && highlightedIndex >= 0 && highlightedIndex < matchingBrokers.length) {
                    handleOptionClick(matchingBrokers[highlightedIndex]);
                    setSearchBrokerOption(false);
                }
            }
        }
        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [matchingBrokers && matchingBrokers.length, searchBrokerOption, highlightedIndex]);

    const dispatchSearch = debounce((keyword: any) => setTimeout(() => dispatch(searchChanged(keyword)), 400), 400);

    const downloadListingsReport = () => {
        dispatch(setMainMessage({ show: true, type: MainMessageType.LOADING, message: (config && config.report && config.report.loadingText) || "Generating Report..." }));
        axiosDownloadReport(`/api/report/download/${regionId || defaultRegionID}`)
            .then((response: any) => {
                clearMessage(dispatch);
                const url = window.URL.createObjectURL(new Blob([response]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', (config && config.report && config.report.exportFileName) || 'DEReport.xlsx');
                document.body.appendChild(link);
                link.click();
            });
    }

    const highlightStyles = {
        backgroundColor: '#D1FFBD',
    };

    return (
        <StyledListingsHeader>
            <StyledInnerContainer>
                <StyledTitle style={{ float: "left", 'marginRight': '15px', 'marginTop': '8px', 'marginBottom': '-8px' }}>My Listings</StyledTitle>
                <StyledSearchBox><StyledSearchInput onClick={() => analytics.fireEvent('searchClick', 'click', 'Property searchbar click')} placeholder="Search by Property Address or Broker Contact..." defaultValue={searchText} ref={inputRef} onChange={showSearch ? handleInput : () => (null)} onKeyDown={handleInputSearch} />
                    <StyledSearchIconBtn onClick={() => searchProperty(value)}><FontAwesomeIcon style={{ 'marginTop': '3px' }} icon={faSearch} /></StyledSearchIconBtn>
                    {searchBroker && showDropdown && matchingBrokers && matchingBrokers.length > 0 && (
                        <StyledUl>
                            <StyledLinkHeader>
                                Broker Contact - click to select:
                            </StyledLinkHeader>
                            {matchingBrokers && matchingBrokers.length > 0 && matchingBrokers.map((broker: Contact, index: number) => (
                                <StyledLink key={broker.contactId} onClick={() => handleOptionClick(broker)}
                                    style={index === highlightedIndex ? highlightStyles : {}}
                                    onMouseEnter={() => setHighlightedIndex(index)}  
                                >
                                    {broker.firstName} {broker.lastName} | {broker.email}
                                </StyledLink>
                            ))}
                        </StyledUl>
                    )}
                </StyledSearchBox>
                <Link id="createListingButton" style={{ float: "right" }} onClick={() => analytics.fireEvent('createListingClick', 'click', 'Create listing button click', 'beacon')} to="/le"><StyledButton>Create Listing</StyledButton></Link>
                {miqImport && <Link id="importFromMIQButton" style={{ float: "right", marginRight: "8px" }} onClick={() => analytics.fireEvent('importFromMIQClick', 'click', 'Import From MIQ button click', 'beacon')} to="/miq/import"><StyledButton>Import from MIQ</StyledButton></Link>}
                {!bulkUpload && <BulkUploadButton sendAnalytics={() => analytics.fireEvent('bulkUploadClick', 'click', 'Bulk upload button click')} />}
                <Link id="download" style={{ float: "right", marginRight: "8px" }} onClick={downloadListingsReport} to=""><StyledButton>{(config && config.report && config.report.downloadText) || 'Listings Report'}</StyledButton></Link>
            </StyledInnerContainer>
        </StyledListingsHeader>
    );
};

export default ListingHeader;

const StyledListingsHeader = styled.div`
    background: #006A4D;
    min-height: 37px;
    max-height: 50px;
    padding:12px 0;
    font-family: ${props => (props.theme.font ? props.theme.font.primary : 'sans-serif')};
    position:sticky;
    top:0;
    z-index:100;
    box-shadow: 0 4px 6px rgba(204,204,204,0.12), 0 2px 4px rgba(204,204,204,0.24);
`;

const StyledUl = styled.ul`
    list-style-type: none;
    display: block;
    margin-left: -10px;
    padding-left: 0px;
    position: absolute;
    top: 38px;
    width: 370px;
    border: 1px solid #cccccc;
    boxShadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
`;

const StyledInnerContainer = styled.div`
    max-width: ${props => props.theme.container.maxWidth};
    width: ${props => props.theme.container.width};
    margin:0 auto;
`;

const StyledTitle = styled.h1`
    display: inline-block;
    color:white;
    float:left;
    text-transform:uppercase;
    font-size: 1.3em;
    font-family: ${props => (props.theme.font ? props.theme.font.bold : 'sans-serif')};
    padding:0px;
`;

const StyledLinkHeader = styled.li`
    background-color: #00a657; 
    padding: 5px;
    color: #ffffff;
    font-weight: bold;
    border-bottom: solid 1px lightgray; 
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const StyledLink = styled.li`
    background-color: white; 
    padding: 10px;
    cursor: pointer;
    border-bottom: solid 1px lightgray; 
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const StyledSearchInput = styled.input`
  float:left;
  border: 0;
  padding: 0;
  color: #666666; 
  min-width: 330px;
  font-weight: ${props => props.theme.font ? props.theme.font.weight.normal : 'normal'};
  font-family: ${props => (props.theme.font ? props.theme.font.primary : 'inherit')}; 
  ::placeholder {color:#cccccc;}
  font-size: 14px;
  text-align: left;
  text-decoration: none;
  vertical-align: text-bottom;
  display:table-cell;
  width:auto;
  &:focus {
        outline:none;
    }
`;

const StyledSearchBox = styled.div`
    background-color: #ffffff !important;
    padding:10px 12px 10px 16px;
    display:table;
    border-radius: 20px;
    float: left;
    input:disabled {
        background: #ffffff !important;
    }
`;

const StyledSearchIconBtn = styled.div`
    color: #1AA083;
    cursor:pointer;
    display:inline-block;
    margin-top:-5px;
    padding-left: 10px;
    padding-right: 10px;
`;