import { SizeTypeOption } from "../../types/common/option";
import { SizeTypeConfig, SizeTypePropertyTypeOptionConfig } from "../../types/config/config";


export const findSizeTypeOptions = (sizeTypeConfig: SizeTypeConfig, propertyType: string): SizeTypeOption[] => {

    let options: SizeTypeOption[] = [];

    if (!sizeTypeConfig || !propertyType || propertyType.length === 0 || !sizeTypeConfig.sizeType) {
        return options;
    }

    sizeTypeConfig.sizeType.forEach(sizeType => {
        if (sizeType.PropertyTypes.includes(propertyType)) {
            options = sizeType.options;
        }
    });

    return options;

}